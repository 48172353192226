import WaveSurfer from 'wavesurfer.js'
import RecordPlugin from 'wavesurfer.js/dist/plugins/record.esm.js'
import { useState } from 'react'

export class AudioRecorderLogic {
  constructor() {
    this.wavesurfer = null;
    this.record = null;
    this.waveformMode = 'scrolling'; // 默认为滚动模式
  }

  // 创建波形显示器
  createWaveSurfer(micContainer, recordingsContainer, onRecordEnd, onProgress) {
    // 销毁之前的实例
    if (this.wavesurfer) {
      this.wavesurfer.destroy()
    }

    // 创建新的 Wavesurfer 实例
    this.wavesurfer = WaveSurfer.create({
      container: micContainer,
      waveColor: 'rgb(200, 0, 200)',
      progressColor: 'rgb(100, 0, 100)',
      // 根据模式决定是否显示波形
      height: this.waveformMode === 'none' ? 0 : 128,
    })

    // 初始化录音插件
    this.record = this.wavesurfer.registerPlugin(
      RecordPlugin.create({
        renderRecordedAudio: false,
        scrollingWaveform: this.waveformMode === 'scrolling',
        continuousWaveform: this.waveformMode === 'continuous',
        continuousWaveformDuration: 30,
      }),
    )

    // 录音结束事件
    this.record.on('record-end', (blob) => {
      onRecordEnd({
        blob
      })
    })

    // 录音进度事件
    this.record.on('record-progress', onProgress)
  }

  // 获取可用的音频设备
  static async getAvailableDevices() {
    return await RecordPlugin.getAvailableAudioDevices()
  }

  // 开始录音
  async startRecording(deviceId) {
    if (!this.record) {
      throw new Error('Record plugin not initialized');
    }
    return await this.record.startRecording({ deviceId })
  }

  // 停止录音
  stopRecording() {
    if (!this.record) {
      throw new Error('Record plugin not initialized');
    }
    return this.record.stopRecording()
  }

  // 暂停录音
  pauseRecording() {
    if (!this.record) {
      throw new Error('Record plugin not initialized');
    }
    return this.record.pauseRecording()
  }

  // 恢复录音
  resumeRecording() {
    if (!this.record) {
      throw new Error('Record plugin not initialized');
    }
    return this.record.resumeRecording()
  }

  // 检查是否暂停
  isPaused() {
    if (!this.record) {
      return false;
    }
    return this.record.isPaused()
  }

  // 检查是否正在录音
  isRecording() {
    if (!this.record) {
      return false;
    }
    return this.record.isRecording()
  }

  // 设置波形模式
  setWaveformMode(mode) {
    this.waveformMode = mode;
    
    // 根据模式设置波形参数
    switch(mode) {
      case 'scrolling':
        this.scrollingWaveform = true;
        this.continuousWaveform = false;
        break;
      case 'continuous':
        this.scrollingWaveform = false; 
        this.continuousWaveform = true;
        break;
      case 'none':
        this.scrollingWaveform = false;
        this.continuousWaveform = false;
        break;
    }
  }

  // 销毁实例
  destroy() {
    if (this.wavesurfer) {
      this.wavesurfer.destroy();
      this.wavesurfer = null;
      this.record = null;
    }
  }
}
