import React, { useEffect, useRef, useState } from 'react';
import { AudioRecorderLogic } from './AudioRecorder';
import WaveSurfer from 'wavesurfer.js';

const AudioRecorderView = () => {
  const [devices, setDevices] = useState([]);
  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [progress, setProgress] = useState('00:00');
  const [recordings, setRecordings] = useState([]);
  const [fileNames, setFileNames] = useState({});
  const [waveformMode, setWaveformMode] = useState('scrolling');
  
  const recorderRef = useRef(null);
  const micContainerRef = useRef(null);
  const recordingsContainerRef = useRef(null);

  useEffect(() => {
    const initRecorder = async () => {
      // 获取可用设备
      const audioDevices = await AudioRecorderLogic.getAvailableDevices();
      setDevices(audioDevices);

      // 初始化录音器
      recorderRef.current = new AudioRecorderLogic();
      recorderRef.current.createWaveSurfer(
        micContainerRef.current,
        recordingsContainerRef.current,
        handleRecordEnd,
        handleProgress
      );
    };

    initRecorder();
  }, []);

  const handleRecordEnd = ({ blob }) => {
    const recordedUrl = URL.createObjectURL(blob);
    
    // 不再直接将 containerDiv 添加到 recordingsContainerRef
    const containerDiv = document.createElement('div');
    containerDiv.className = 'border border-base-300 rounded-box p-4';
    
    // 为录音创建新的波形显示
    const recordedWavesurfer = WaveSurfer.create({
      container: containerDiv,
      waveColor: 'rgb(200, 100, 0)', 
      progressColor: 'rgb(100, 50, 0)',
      url: recordedUrl,
    });

    const newRecording = {
      id: Date.now(),
      url: recordedUrl,
      wavesurfer: recordedWavesurfer,
      isPlaying: false,
      type: blob.type,
      container: containerDiv
    };
    
    setRecordings(prev => [...prev, newRecording]);
  };

  const handleProgress = (time) => {
    const formattedTime = [
      Math.floor((time % 3600000) / 60000),
      Math.floor((time % 60000) / 1000),
    ]
      .map((v) => (v < 10 ? '0' + v : v))
      .join(':');
    setProgress(formattedTime);
  };

  const handleRecord = async () => {
    if (isRecording || isPaused) {
      await recorderRef.current.stopRecording();
      setIsRecording(false);
      setIsPaused(false);
    } else {
      const deviceId = document.getElementById('mic-select').value;
      await recorderRef.current.startRecording(deviceId);
      setIsRecording(true);
    }
  };

  const handlePause = async () => {
    if (isPaused) {
      await recorderRef.current.resumeRecording();
      setIsPaused(false);
    } else {
      await recorderRef.current.pauseRecording();
      setIsPaused(true);
    }
  };

  const handlePlayPause = (recording) => {
    recording.wavesurfer.playPause();
    setRecordings(prev => prev.map(r => {
      if (r.id === recording.id) {
        return { ...r, isPlaying: !r.isPlaying };
      }
      return r;
    }));
  };

  const handleWaveformChange = (e) => {
    const mode = e.target.value;
    setWaveformMode(mode);
    
    // 更新录音器的波形设置
    recorderRef.current.setWaveformMode(mode);
    
    // 重新创建波形显示
    recorderRef.current.createWaveSurfer(
      micContainerRef.current,
      recordingsContainerRef.current,
      handleRecordEnd,
      handleProgress
    );
  };

  const handleDownload = (recording) => {
    const now = new Date();
    const timestamp = `${now.getFullYear()}${(now.getMonth()+1).toString().padStart(2,'0')}${now.getDate().toString().padStart(2,'0')}${now.getHours().toString().padStart(2,'0')}${now.getMinutes().toString().padStart(2,'0')}`;
    const customName = fileNames[recording.id] || 'recording';
    const extension = recording.type.split(';')[0].split('/')[1] || 'webm';
    const fileName = `${timestamp}-${customName}.${extension}`;
    
    console.log('下载录音文件:', {
      fileName,
      recordingId: recording.id,
      customName,
      type: recording.type,
      timestamp: now.toISOString()
    });
    
    return fileName;
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl mb-4">按下录制开始录音 🎙️</h1>

      <p className="mb-4">
        📖 <a href="https://wavesurfer.xyz/docs/classes/plugins_record.RecordPlugin" className="link">
          Record plugin 文档
        </a>
      </p>

      <div className="flex gap-4 mb-4">
        <button 
          onClick={handleRecord}
          className="btn btn-primary min-w-[5rem]"
          disabled={isRecording && !devices.length}
        >
          {isRecording ? '停止' : '录制'}
        </button>
        
        {isRecording && (
          <button 
            onClick={handlePause}
            className="btn btn-secondary min-w-[5rem]"
          >
            {isPaused ? '继续' : '暂停'}
          </button>
        )}
        
        <select id="mic-select" className="select select-bordered">
          <option value="" hidden>选择麦克风</option>
          {devices.map((device) => (
            <option key={device.deviceId} value={device.deviceId}>
              {device.label || device.deviceId}
            </option>
          ))}
        </select>
      </div>

      <div className="flex gap-4 mb-4">
        <label className="label">
          <span className="label-text mr-2">波形显示模式:</span>
          <select 
            className="select select-bordered"
            value={waveformMode}
            onChange={handleWaveformChange}
          >
            <option value="scrolling">滚动波形</option>
            <option value="continuous">连续波形</option> 
            <option value="none">无波形</option>
          </select>
        </label>
      </div>

      <p className="mb-4">{progress}</p>

      <div ref={micContainerRef} className="border border-base-300 rounded-box p-4 mb-4"></div>
      
      <div className="mt-4">
        <h2 className="text-xl mb-4">录音列表</h2>
        <div ref={recordingsContainerRef}>
          {recordings.map((recording) => (
            <div key={recording.id} className="mb-4">
              <div className="flex items-center gap-4 mb-2">
                <button
                  onClick={() => handlePlayPause(recording)}
                  className="btn btn-primary min-w-[5rem]"
                >
                  {recording.isPlaying ? '暂停' : '播放'}
                </button>
                <input
                  type="text"
                  placeholder="请输入文件名"
                  className="input input-bordered"
                  onChange={(e) => setFileNames(prev => ({
                    ...prev,
                    [recording.id]: e.target.value
                  }))}
                />
                <a
                  href={recording.url}
                  download={handleDownload(recording)}
                  className="btn btn-secondary min-w-[5rem]"
                >
                  下载录音
                </a>
              </div>
              <div ref={node => node && node.appendChild(recording.container)} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AudioRecorderView; 