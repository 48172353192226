import React from 'react';
import { Link } from 'react-router-dom';

const navConfig = {
  "英语学习": [
    { path: "/flash-word", name: "Flash Word" },
    { path: "/flash-word", name: "Flash Word" },
    { path: "/AudioRegionPlayerSample", name: "AudioRegionPlayerSample" },
    { path: "/AudioRecorder", name: "AudioRecorder" },
    { path: "/EnglishJM", name: "EnglishJM" },
    { path: "/EnglishArticleTextProcessorView", name: "EnglishArticleTextProcessorView" },
    { path: "/WordListCheck", name: "WordListCheck" },
    { path: "/YouglishWidget", name: "YouglishWidget" }
  ],
  "语音合成": [
    { path: "/Azure-TTS", name: "AzureTTS" },
    { path: "/TTS-Config-Page", name: "TTS Config" },
    { path: "/TTS-Page", name: "TTS Page" },
    { path: "/SpeechToText", name: "SpeechToText" }
  ],
  "音频处理": [
    { path: "/AudioProcessor", name: "AudioProcessor" }
  ],
  "Google Drive": [
    { path: "/GoogDriveListSave", name: "GoogDriveListSave" },
    { path: "/DriveFileViewerGoogleDrive", name: "DriveFileViewerGoogleDrive" }
  ],
  "时区": [
    { path: "/TimeZoneAlign", name: "TimeZoneAlign" }
  ],
  "依赖图": [
    { path: "/EchartDependency", name: "EchartDependency" },
    { path: "/DependencyVisjsGraph", name: "DependencyVisjsGraph" }
  ],
  "工具": [
    { path: "/JsonFormat", name: "JsonFormat" },
    { path: "/IOSShortCut", name: "IOSShortCut" },
    { path: "/MCLogView", name: "MCLogView" }
  ],
  "Baby": [
    { path: "/BabyRecord", name: "BabyRecord" },
    { path: "/BabyHeadShape", name: "BabyHeadShape" },
  ]
};

const Home = () => {
  return (
      <div className="app-container">
        <nav className="p-4">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
            {Object.entries(navConfig).map(([category, items]) => (
              <div key={category} className="card bg-base-100 shadow-xl">
                <div className="card-body">
                  <h2 className="card-title">{category}</h2>
                  <div className="flex flex-col space-y-2">
                    {items.map(item => (
                      <Link 
                        key={item.path} 
                        to={item.path}
                        className="btn btn-ghost btn-sm justify-start"
                        onClick={item.path === "/IOSShortCut" ? (e) => {
                          e.preventDefault();
                          window.open("/IOSShortCut", "_blank");
                        } : undefined}
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </nav>
      </div>
  );
};

export default Home;
